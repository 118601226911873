$white      : #FFFFFF;
$black      : #707070;
$primary    : #BF884A;
$secondary  : #FFDCB5;
$muted 		: #F8F7F0;

$colors: (
	'white'      : $white,
	'black'      : $black,
	'primary'    : $primary,
	'secondary'  : $secondary,
	'muted'		 : $muted,
);

@each $index, $color in $colors{
	.bg--#{$index}{
		background-color:		$color;
		transition: 			background-color 0.4s ease;
	}

	.color--#{$index}{
		color: 					$color;
		transition: 			color 0.4s ease;

		svg{
			fill: $color;
			transition: fill 0.4s ease;
		}
	}

	.hover-color--#{$index}{
		&:hover{
			color: 				$color !important;
			
			svg{
				fill: $color !important;
			}
		}
	}

	.hover-bg--#{$index}{
		&:hover{
			background:			$color !important;
		}
	}
}