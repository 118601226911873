.button{
	border: 2px solid $primary;
	color: $primary;
	display: inline-block;
	font-family: 'Poppins', sans-serif;;
	font-weight: 700;
	font-size: 17px;
	min-width: 200px;
	padding: 6px 12px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 0.5s ease;

	&:hover{
		background: $primary;
		color: $white;
	}

	&--full{
		background: $primary;
		color: $white;

	    /*&:hover{
		    background: $white;
		    color: $primary;
	    }*/
	}

}