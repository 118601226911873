*{
	box-sizing: border-box;
}

html{
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html, body{
	border: 0;
	margin: 0;
	padding: 0;
}

body{
	overflow-x: hidden; 
}

button{
	cursor: pointer;
}

.lazyload, .lazyloading{
	opacity: 0;
	transition: all 0.1s ease;
}

svg.lazyload{
	opacity: 1;
}

.lazyloaded{
	opacity: 1;
	transition: all 0.1s ease;
}

img {
    max-width: 100%;
}

// sections

.section{
	padding-bottom: 110px;
	padding-top: 110px;

	&-large{
		padding-bottom: 200px;
		padding-top: 200px;
	}

	&-small{
		padding-bottom: 75px;
		padding-top: 75px;
	}

	@include breakpoint('1279px'){
		padding-bottom: 80px;
		padding-top: 80px;

		&-large{
			padding-bottom: 150px;
			padding-top: 150px;
		}

		&-small{
			padding-bottom: 50px;
			padding-top: 50px;
		}
	}

	@include breakpoint('767px'){
		padding-bottom: 50px;
		padding-top: 50px;

		&-large{
			padding-bottom: 100px;
			padding-top: 100px;
		}

		&-small{
			padding-bottom: 50px;
			padding-top: 50px;
		}
	}
}

.overflow-hidden{
	overflow: hidden;
}

// positions

.position-relative{
	position: relative;
}

.position-cover{
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

// backgrounds

.background-cover{
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
    width: 100%;
    height: 100%;
}

// flex

.height-1-1{
	height: 100%;
}

.height-1-2{
	height: 50%;
}

.width-1-1{
	width: 100%;
}

.width-1-2{
	width: 50%;
}

@media only screen and (min-width: 961px){
	.height-md-1-1{
		height: 100%;
	}

	.height-md-1-2{
		height: 50%;
	}

	.width-md-1-1{
		width: 100%;
	}

	.width-md-1-2{
		width: 50%;
	}
}

@media only screen and (min-width: 1201px){
	.height-lg-1-1{
		height: 100%;
	}

	.height-lg-1-2{
		height: 50%;
	}

	.width-lg-1-1{
		width: 100%;
	}

	.width-lg-1-2{
		width: 50%;
	}
}