.arrangementen-item{
	&__content{
		padding-right: 100px !important;
	}

	&__cms{
		padding-left: 30px;

		h3{
			margin-left: -30px;
			margin-top: 22px;
			margin-bottom: 14px;

			&:first-child{
				margin-top: 0;
			}
		}

		ul{
			padding-left: 20px;
		}
	}

	&__image{
		height: 80%;
		position: relative;
		width: calc(100% + 100px);
		float: right;
	}

	@include breakpoint($bp-md){
		&__content{
			padding-right: 1.5rem !important;
		}
	}
}

.arrangementen-information{
	img {
		width: 100%;

		@include breakpoint($bp-md){
			width: 45vw;
			margin-bottom: 50px;
		}

		@include breakpoint($bp-sm){
			width: 50vw;
			margin-bottom: 50px;
		}
	}
}