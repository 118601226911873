.praktisch-voorzieningen{
	margin-bottom: 200px;

	&__image-wrap{
		height: 100%;
		position: relative;
		width: 100%;
	}
	&__image{
		position: absolute;
		left: 0;
		height: calc(100% + 100px);
		top: 0;
		width: 100%;
	}

	@include breakpoint($bp-md){
		margin-bottom: 50px;
		margin-top: 27vw;

		.row{
			flex-direction: column-reverse;
		}

		&__image-wrap{
			height: auto;
			width: auto;
		}

		&__image{
			margin-top: -27vw;
			height: 55vw;
			position: relative;
			width: 40vw;
		}
	}

	@include breakpoint($bp-sm){
		margin-top: 35vw;

		&__image-wrap{
			height: auto;
			width: auto;
		}

		&__image{
			margin-top: -35vw;
			height: 70vw;
			position: relative;
			width: 50vw;
		}
	}
}

.faq{
	.faq-item{
		border: 1px solid $muted;
		margin-top: 0;

		&__bar{
			background: $muted;
			cursor: pointer;
			transition: all 0.4s ease;
		}

		&:nth-child(even){
			border: 1px solid rgba($muted, 0.5);
		}

		&:nth-child(even) .faq-item__bar{
			background: rgba($muted, 0.5);
		}

		h3{
			margin-bottom: 0;
			text-transform: none;
            padding-right: 15px;
		}

		&__chevron{
			display: block;
			padding: 12px 10px 8px 10px;
			transition: all 0.4s ease;
			width: auto;

			svg{
				display: inline-block;
				fill: $primary;
				height: 16px;
				width: 30px;
				transition: all 0.4s ease;
			}

			&:after{
				display: none;
			}
		}

		&__content{
			max-height: 0;
			overflow: hidden;
			transition: all 0.8s ease;
		}
	}


		.is-open{
			.faq-item__chevron{
				svg{
					transform: rotate(180deg) !important;
				}
			}
		}
}