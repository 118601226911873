.home-concept{
	margin-bottom: 270px;

	&__image-wrap{
		height: 100%;
		position: relative;
		width: 100%;
	}
	&__image{
		position: absolute;
		top: 0;
		left: 0;
		height: calc(100% + 100px);
		width: 100%;
	}

	&__barrel{
		position: absolute;
		bottom: -220px;
		left: calc(50% + 400px);
		height: 403px;
		width: 481px;
	}

	@include breakpoint($bp-md){
		margin-bottom: 50px;
		margin-top: 27vw;

		&__image-wrap{
			height: auto;
			width: auto;
		}

		&__image{
			margin-top: -27vw;
			height: 55vw;
			position: relative;
			width: 40vw;
		}

		&__barrel{
			position: absolute;
			bottom: auto;
			top: -25vw;
			left: auto;
			right: -10vw;
			height: 35vw;
			width: 50vw;
		}
	}

	@include breakpoint($bp-sm){
		margin-top: 35vw;

		&__image-wrap{
			height: auto;
			width: auto;
		}

		&__image{
			margin-top: -35vw;
			height: 70vw;
			position: relative;
			width: 50vw;
		}
	}
}

.home-gallery{
	position: relative;

	.col-md-6, .glide{
		position: static;
	}

	&:after{
		background: $muted;
		bottom: 0;
		content: '';
		height: 50%;
		left: -50vw;
		position: absolute;
		width: 500vw;
		z-index: -1;
	}

	&__slider{
		width: 400%;

		.image{
			padding-bottom: 67%;
			width: 100%;
		}

		.glide__arrow{
			background: rgba($white, 0.8);
			box-shadow: none;
			border: none;
			color: $primary;
			padding: 14px 14px 10px 18px;
			position: absolute;
			transition: all 0.5s ease;
			
			svg{
				color: $primary;
				height: 33px;
				min-height: 33px;
				min-width: 19px;
				width: 19px;
			}

			&--right{
				right: 0;
				top: 35%;
			}

			&:hover{
				background: rgba($white, 0.98);
			}

			@include breakpoint($bp-md){
				padding: 10px 10px 6px 14px;

				svg{
					height: 20px;
					min-height: 20px;
					min-width: 12px;
					width: 12px;
				}
			}
		}
	}

	@include breakpoint($bp-md){
		.home-gallery__slider .glide__arrow--right {
	    	right: 0;
	    	top: 35%;
		}
	}

	@include breakpoint($bp-sm){
		.home-gallery__slider .glide__arrow--right {
	    	right: 0;
	    	top: 30%;
		}
	}
}

.home-praktisch{
	margin-bottom: 100px;

	&__image-wrap{
		height: 100%;
		position: relative;
		width: 100%;
	}
	&__image{
		position: absolute;
		top: 0;
		left: 0;
		height: calc(100% + 100px);
		width: 100%;
	}

	&__content{
		position: relative;

		&:after{
			background: $muted;
			content: '';
			height: 100%;
			left: -75vw;
			position: absolute;
			top: 0;
			width: 250vw;
			z-index: -1;
		}
	}

	@include breakpoint($bp-md){
		margin-bottom: 50px;
		margin-top: calc(27vw + 50px);

		&__image-wrap{
			height: auto;
			width: auto;
		}

		&__image{
			margin-top: -27vw;
			height: 55vw;
			position: relative;
			width: 40vw;
		}

		&__content{
			margin-top: -50px;
			padding-top: 100px;
		}
	}

	@include breakpoint($bp-sm){
		margin-top: calc(35vw + 50px);

		&__image-wrap{
			height: auto;
			width: auto;
		}

		&__image{
			margin-top: -35vw;
			height: 70vw;
			position: relative;
			width: 50vw;
		}
	}
}