@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.lato{
	font-family: 'Lato', sans-serif;
}

.nunito{
    font-family: "Nunito Sans", sans-serif;
}

.merienda{
	font-family: 'Merienda', cursive;
}

.poppins{
	font-family: 'Poppins', sans-serif;
}