.teaser h2 {
    padding-bottom: 1.5rem;
}

.footer{
	border-top: 16px solid $secondary;

	.nav{
		list-style-type: none;

		a{
			color: $white;
			display: inline-block;
            font-family: 'Poppins', sans-serif;
			font-size: 15px;
			font-weight: 500;
            letter-spacing: 0.3px;
			padding: 3px 0;
			text-transform: uppercase;
			transition: all 0.5s ease;

			&:hover{
				color: $secondary;
			}
		}

		@include breakpoint($bp-sm){
			padding-left: 0;
		}
	}

	.contact-link{
		span{
			min-width: 60px;
		}
		a{
			color: $white;
			min-width: 180px;
			text-align: right;
			transition: all 0.5s ease;
            text-decoration: none;

			&:hover{
				color: $secondary;
			}
		}
	}

	.social-icon{
		height: 30px;
		margin-left: 12px;
		min-height: 30px;
		min-width: 30px;
		width: 30px;
	}

	&__lower{

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                float: left;
                margin-right: 15px;

                @include breakpoint($bp-sm){
                    float:none;
                    margin-right: 0;
                    text-align: center;
		        }

                &:after {
                    content: '|';
                    display: inline-block;
                    margin-left: 15px;                    

                    @include breakpoint($bp-sm){
                        display:none;    
		            }
                }
                
                &:last-child {
                    margin-right: 0;
                    
                    &:after {
                        display:none; 
                    }
                }

            }

        }

		a{
			color: $secondary;
			display: inline-block;
			padding: 3px 12px;
			text-decoration: none;
			transition: all 0.5s ease;

			&:hover{
				color: $white;
			}

			&:first-child{
				padding-left: 0;
			}

			&:last-child{
				padding-right: 0;
			}
		}
	}

}