/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
  	scrollbar-width: auto;
  	scrollbar-color: $primary #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
  	width: 6px;
  }

  *::-webkit-scrollbar-track {
  	background: $muted;
  }

  *::-webkit-scrollbar-thumb {
  	background-color: $primary;
  	border-radius: 10px;
  	border: 3px solid #muted;
  }

  .z-index-1{
  	z-index: 1;
  }

  .ratio-16-9{
  	padding-bottom: calc(9/16 * 100%);
  }

  .ratio-4-3{
  	padding-bottom: calc(3/4 * 100%);
  }

  .order-1{
  	order: 1;
  }

  .order-2{
  	order: 2;
  }

  .order-3{
  	order: 3;
  }

  .hero-banner{
  	&__slider{
  		.image{
  			padding-bottom: 46%;
  			width: 100%;
  		}

  		.glide__arrow{
  			background: rgba($white, 0.6);
  			box-shadow: none;
  			border: none;
  			color: $primary;
  			padding: 14px 14px 10px 18px;
  			position: absolute;
  			transition: all 0.5s ease;

  			svg{
  				color: $primary;
  				height: 33px;
  				min-height: 33px;
  				min-width: 19px;
  				width: 19px;
  			}

  			&--right{
  				right: 0;
  				bottom: 0;
  			}

  			&:hover{
  				background: rgba($white, 0.98);
  			}

  			@include breakpoint($bp-md){
  				padding: 10px 10px 10px 14px;

  				svg{
  					height: 20px;
  					min-height: 20px;
  					min-width: 12px;
  					width: 12px;
  				}
  			}
  		}
  	}	

  	&--big{
  		.image{
  			padding-bottom: 54%;
  		}
  	}

  	&__overlap{
  		margin-top: -100px;
  		position: relative;

  		&__waves{
  			svg{
  				height: 180px;
  				left: -98px;
  				position: absolute;
  				top: -140px;
  				width: 180px;

  				@include breakpoint($bp-xl){
  					left: 2.5rem;
  					height: 120px;
  					width: 120px;
  					top: -80px;
  				}

  				@include breakpoint($bp-md){
  					left: 1.5rem;
  				}

  				@include breakpoint($bp-sm){
  					height: 80px;
  					width: 80px;
  					top: -40px;
  				}
  			}
  		}

  		@include breakpoint($bp-md){
  			margin-top: 0;
  		}
  	}
  }

  
.section.teaser {
	padding-bottom: 110px;
	padding-top: 110px;

	@include breakpoint('1279px'){
		padding-bottom: 80px;
		padding-top: 80px;
	}

}