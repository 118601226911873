body{
	color: $black;
    font-family: "Nunito Sans", sans-serif;
	font-size: 18px;
	font-weight: 300;
}

button{
	font-family: 'Poppins', sans-serif;;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5{
	color: $primary;
	font-family: 'Merienda', sans-serif;
	font-weight: 700;
	line-height: 125%;
	margin-top: 0;
}

h1, .h1{
	font-size: 35px;
	margin-bottom: 18px;
}

h2, .h2{
	font-size: 35px;
	margin-bottom: 18px;
}

h3, .h3{
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 18px;
	text-transform: uppercase;
}

@include breakpoint($bp-md){
	h1, .h1{
		font-size: 32px;
	}

	h2, .h2{
		font-size: 32px;
	}

	h3, .h3{
		font-size: 18px;
	}
}

p{
	line-height: 150%;
	margin-bottom: 0;
	margin-top: 0;

	a{
		color: $primary;
		text-decoration: underline;

		&:hover{
			color: darken($primary, 10);
		}
	}
}

p + p{
	margin-top: 18px;
}

a{
	text-decoration: none;
}

ul {
	line-height: 150%;
}

em{
	color: $secondary;
	font-weight: 500;
	font-style: normal;
}

.light-section{
	h1, h2, h3, h4, h5, p, p > a{
		color: $white;
	}
}

$fontsizes: (
	15,
	16,
	17,
	18,
	35,
	);

@each $fontsize in $fontsizes{
	.fs--#{$fontsize}{
		font-size: #{$fontsize}px;
	}
}

$lineheights: (
	75,
	100,
	125,
	150,
	175,
	);

@each $lnh in $lineheights{
	.lnh--#{$lnh}{
		line-height: percentage($lnh/100);
	}
}

.text-bold{
	font-weight: 700;
}

.text-regular{
	font-weight: 400;
}

.text-uppercase{
	text-transform: uppercase;
}

.text-normal{
	text-transform: inherit;
}

.text-left{
	text-align: left;
}

.text-right{
	text-align: right;
}

@media only screen and (min-width: 961px){
    .text-md-right{
		text-align: right;
    }
}

.text-center{
	text-align: center;
}

.text-tablet-center{
	@include breakpoint($bp-md){
		text-align: center;
	}
}

.text-mobile-center{
	@include breakpoint($bp-sm){
		text-align: center;
	}
}

.text-decoration-none{
	text-decoration: none;
}