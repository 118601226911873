input, select, button, a{
	&:focus, &:active{
		outline: none;
	}
}

.form{
	.input-text{
		border: 1px solid $primary;
		color: $black;
        font-family: "Nunito Sans", sans-serif;
		font-size: 18px;
		line-height: 125%;
		padding: 12px 16px;
		width: 100%;

		&::placeholder{
			color: $black;
		}
	}

	.input-textarea{
		height: 100%;
		min-height: 150px;
	}

	.form-subtitle{
		display: inline-block;
        font-family: "Nunito Sans", sans-serif;
		padding: 12px 16px;
	}

	.select{
		border: 1px solid $primary;
		color: $black;
		cursor: pointer;
        font-family: "Nunito Sans", sans-serif;
		font-size: 18px;
		line-height: 125%;
		margin-left: 20px;
		padding: 12px 16px;
		width: 100%;
	}

	.checkbox{
		.label{
			cursor: pointer;
			padding: 0;
			padding-left: 32px;
			padding-right: 16px;
			position: relative;

			&:before{
				border: 1px solid $primary;
				content: '';
				height: 18px;
				left: 0;
				position: absolute;
				top: 3px;
				width: 18px;
			}

			&:after{
				border-bottom: 3px solid $primary;
				border-right: 3px solid $primary;
				content: '';
				display: inline-block;
				height: 12px;
				left: 5px;
				position: absolute;
				top: 4px;
				transform: rotate(45deg);
				width: 8px;
				opacity: 0;
			}

			&:hover:after{
				opacity: 0.5;
			}

            a {
                color: inherit;
                text-decoration: underline;
            }
		}

		input:checked + .label:after{
			opacity: 1;
		}
	}

	.form-table-row{
		border-bottom: 1px solid $primary;
		display: flex;
		justify-content: space-between;
		padding: 12px 0;

		.name, .price{
			padding: 8px 16px;
		}

		.price{
			flex: 1;
		}

		.name{
			min-width: calc(50% + 16px);
		}

		.select{
			padding: 4px 10px;
		}

		.amount{
			padding-left: 16px;
		}

		@include breakpoint($bp-md){
			flex-wrap: wrap;

			.name, .price{
				min-width: 50%;
			}

			.amount{
				margin-top: 8px;
				width: 200px;
			}
		}

		@include breakpoint($bp-sm){
			flex-wrap: wrap;

			.name, .price{
				min-width: 100%;
			}

			.amount{
				width: 200px;
			}
		}
	}
}