.header{
	.navbar{
		list-style-type: none;

		a{
	        font-family: 'Lato', sans-serif;
			color: #838383;
			display: inline-block;
			font-size: 17px;
			font-weight: 600;
			padding: 32px 16px 18px 16px;
			text-transform: uppercase;
			transition: all 0.5s ease;

			&:hover, &.active{
				color: $primary;
			}

			@include breakpoint($bp-lg){
				padding: 32px 12px 18px 12px;
			}
		}
	}

	.button{
		margin-top: 14px;
		padding-bottom: 3px;
		padding-top: 3px;
		z-index: 10;

		@include breakpoint($bp-lg){
			min-width: 140px;
		}
	}

	.logo > svg{
		height: 97px;
		min-height: 97px;
		min-width: 174px;
		position: relative;
		width: 174px;
		z-index: 10;

		@include breakpoint($bp-lg){
			height: 80px;
			min-height: 80px;
			min-width: 144px;
			width: 144px;
		}

		@include breakpoint($bp-sm){
			height: 65px;
			min-height: 65px;
			min-width: 120px;
			width: 120px;
		}
	}

	.hamburger{
		cursor: pointer;
		margin-left: 20px;
		margin-top: 14px;
		padding: 10px 0;
		position: relative;
		width: 34px;
		z-index: 11;

		span{
			background-color: $primary;
			display: block;
			height: 3px;
			opacity: 1;
			transition: all 0.3s ease;
			width: 100%;
		}

		&:before, &:after{
			background-color: $primary;
			content: '';
			display: block;
			height: 3px;
			position: absolute;
			transition: all 0.3s ease;
			width: 100%;
		}

		&:before{	
			left: 0;	
			top: 0;
		}

		&:after{		
			bottom: 0;
			left: 0;
		}

		/*&:hover{
			span{
				background-color: $black;
			}

			&:before, &:after{
				background-color: $black;
			}
		}*/
	}
}


@media (max-width: 959px) {
  
    .header--mobile-active{
	    position: relative;
	    z-index: 10;

	    .hamburger{
		    span{
			    opacity: 0;
		    }

		    &:before, &:after{
			    background-color: $primary;
		    }

		    &:before{
			    transform: rotate(45deg) translateX(7px) translateY(8px);
		    }

		    &:after{
			    transform: rotate(-45deg) translateX(7px) translateY(-7px);
		    }
	    }

	    .navbar{
		    background: $secondary;
		    display: flex !important;
		    flex-direction: column;
		    /*min-height: 100vh;*/
		    left: 0;
		    margin-top: 0;
		    padding-bottom: 50px;
		    padding-left: 0;
		    padding-top: 150px;
		    position: absolute;
		    top: 0;
		    width: 100%;

		    li{
			    a{
				    color: $primary;
				    font-size: 20px;
				    padding: 13px 28px !important;

				    &:hover{
					    color: $black;
				    }
			    }
		    }
	    }
    }

}

@media (max-width: 767px) {

    .header--mobile-active .navbar { 
		    padding-top: 120px;
    }

}