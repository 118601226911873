// snelle breakpoint syntax
@import 'breakpoint-sass';
@include breakpoint-set( 'default feature', 'max-width' );

// for sass breakpoints
$bp-sm: 641px;
$bp-md: 961px;
$bp-lg: 1201px;
$bp-xl: 1601px;

// container

.container{
	max-width: 1400px;
	padding-left: 50px;
	padding-right: 50px;

	@include breakpoint($bp-xl){
		padding-left: 40px;
		padding-right: 40px;
	}

	@include breakpoint($bp-md){
		padding-left: 15px;
		padding-right: 15px;
	}
}

@import "colors",
"fonts",
"layout",
"typography",
"buttons",
"forms",
"modules",
"header",
"footer",
"home",
"praktisch",
"text",
"arrangementen";